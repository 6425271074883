<template>
  <div class="share-list-products" v-if="formattedList.length >= 3">
    <div class="share-list-products__wrapper">
      <label
        v-for="itm in formattedList"
        :key="itm.trackingCode"
        class="share-list-products__item"
      >
        <div class="share-list-products__img">
          <img :src="itm.photo" />
        </div>
        <div class="share-list-products__info">
          <div class="share-list-products__title">{{ itm.name }}</div>
          <div class="share-list-products__about">
            <span>{{ itm.country }}</span>
            <span>{{ itm.trackingCode }}</span>
          </div>
        </div>
        <div class="share-list-products__checkbox">
          <div class="switch-input">
            <input
              :checked="isAgreeShare || itm.isActive"
              :disabled="isAgreeShare || itm.isActive"
              @input="() => onChange(itm)"
              type="checkbox"
            />
            <span></span>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { getMediaLink } from "@/utils/utils";
import { mapActions, mapGetters } from "vuex";

const countryNames = {
  US: "USA",
  CN: "China",
  TR: "Turkey",
};
export default {
  name: "ShareList",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("parcels", ["sharedParcelList", "wholeParcelList"]),
    ...mapGetters("user", ["isAgreeShare"]),
    formattedList() {
      return this.sharedParcelList.map((p) => ({
        id: p.iParcel_id || p.id,
        country: countryNames[p.pr_587 || p.pr275_Name],
        isActive: Boolean(p.pr830),
        trackingCode: p.p_pr_296 || p.pr_296,
        name: p.pr474 || p.pr331 || this.$t("needDescriptionPC"),
        photo: getMediaLink(p.pr_600 || p.PathToPhoto || ""),
      }));
    },
  },
  methods: {
    ...mapActions("parcels", ["toggleShareParcel"]),
    async onChange(itm) {
      if (this.isLoading) return false;
      this.isLoading = true;
      await this.toggleShareParcel({
        id: itm.id,
        value: !itm.isActive,
      });
      this.isActive = !this.isActive;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.share-list-products {
  margin-left: -16px;
  margin-right: -16px;
  flex: 1 auto;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &__wrapper {
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;

    &:after {
      display: block;
      content: "";
      width: calc(100% - 48px);
      right: -16px;
      position: absolute;
      height: 1px;
      background: #f1f1f1;
      bottom: -1px;
    }
  }

  &__img {
    position: relative;
    width: 48px;
    height: 48px;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__title {
    color: #282828;
    font-size: 16px;
    font-family: "Helvetica Neue Medium";
  }

  &__info {
    font-size: 14px;
    color: #bfbfbf;
    flex: 1 auto;
    padding-left: 16px;
    padding-right: 16px;

    span {
      display: inline-block;
      min-width: 60px;
    }
  }
}
</style>