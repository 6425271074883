<template>
  <div class="address-page share-products">
    <div class="address-page__header">
      <div class="address-page__back" @click="$router.push('/profile')">
        <img src="@/assets/img/arr-l-w.svg" />
      </div>
      <div class="address-page__title">{{ $t("sharePageTitle") }}</div>
    </div>
    <div class="address-page__content">
      <share-whole :defaultValue="isAgreeShare" />
      <transition name="fast-fade">
          <share-list />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ShareList from './ShareList.vue';
import ShareWhole from './ShareWhole.vue';
export default {
  name: "ShareProduct",
  components: { ShareWhole, ShareList },
  computed: {
    ...mapGetters("user", ["isAgreeShare"]),
  },
};
</script>

<style lang="scss">
.share-products {
    .address-page__content {
        flex-direction: column;
    }
}
</style>