<template>
  <div class="share-whole-products">
    <label class="share-whole-products__checkbox">
      <div class="share-whole-products__checkbox-title">
        {{ $t("sharePageCheckboxLabel") }}
      </div>
      <div class="switch-input">
        <input :checked="isActive" @input="onChange" type="checkbox" />
        <span></span>
      </div>
    </label>

    <div class="share-whole-products__text">
      {{ $t("shareWholeText") }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ShareWhole",
  props: {
    defaultValue: Boolean,
  },
  data() {
    return {
      isActive: this.defaultValue || false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("parcels", ["sharedParcelList"]),
  },
  methods: {
    ...mapActions("user", ["toggleIsShare"]),
    ...mapActions("parcels", ["toggleShareParcel"]),
    async onChange() {
      if (this.isLoading) return false;
      this.isLoading = true;
      await this.toggleIsShare(!this.isActive);
      this.isLoading = false;
      if (!this.isActive) {
        await this.enableAll();
      }
    },
    async enableAll() {
      await Promise.all(
        this.sharedParcelList.map(
          async (p) =>
            await this.toggleShareParcel({
              id: p.iParcel_id || p.id,
              value: true,
            })
        )
      );
    },
  },
  watch: {
    defaultValue(newV) {
      this.isActive = newV
    }
  }
};
</script>

<style lang="scss">
.share-whole-products__text {
  color: #333333;
  font-size: 14px;
  padding-top: 8px;
  line-height: 18px;
  border-top: 1px solid #de9e24;
  margin-bottom: 20px;
}
.share-whole-products__checkbox {
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 16px;
}
.share-whole-products__checkbox:active {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.share-whole-products__checkbox-title {
  font-size: 16px;
  line-height: 1;
  flex: 1 auto;
  font-family: "Helvetica Neue Medium";
  color: #282828;
  font-weight: 400;
}
.switch-input {
  cursor: pointer;
}
.switch-input input {
  display: none;
}
.switch-input input + span {
  width: 48px;
  height: 28px;
  border-radius: 14px;
  transition: all 0.3s ease;
  display: block;
  position: relative;
  background: #696969;
}
.switch-input input + span:before,
.switch-input input + span:after {
  content: "";
  display: block;
  position: absolute;
  transition: all 0.3s ease;
  box-sizing: border-box;
}
.switch-input input + span:before {
  top: 5px;
  left: 5px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 5px solid #fff;
}
.switch-input input + span:after {
  top: 5px;
  left: 32px;
  width: 6px;
  height: 18px;
  border-radius: 40%;
  transform-origin: 50% 50%;
  background: #fff;
  opacity: 0;
}
.switch-input input + span:active {
  transform: scale(0.92);
}

.switch-input input:checked + span {
  background: #91c258;
}
.switch-input input:checked + span:before {
  width: 0px;
  border-radius: 3px;
  margin-left: 27px;
  border-width: 3px;
  background: #fff;
}
.switch-input input:checked + span:after {
  animation: blobChecked 0.35s linear forwards 0.2s;
}
.switch-input input:not(:checked) + span:before {
  animation: blob 0.85s linear forwards 0.2s;
}

@keyframes blob {
  0%,
  100% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.12, 0.94);
  }
  60% {
    transform: scale(0.96, 1.06);
  }
}

@keyframes blobChecked {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }
  30% {
    transform: scaleX(1.44);
  }
  70% {
    transform: scaleX(1.18);
  }
  50%,
  99% {
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 0;
  }
}
</style>